//====================== IMPORTANT NOTE: Do not change import order! =====================//
// Reset styling.
@import "reset";
@import "lib";

html,
body,
#root {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: #000;
  &:visited {
    color: #000;
  }
  &:hover {
    color: #4fb848;
  }
}

.bottom-up {
  -webkit-animation-name: bottomUp;
  animation-name: bottomUp;
  animation-duration: 0.4s;
}

.right-to-left {
  -webkit-animation-name: rightToLeft;
  animation-name: rightToLeft;
  animation-duration: 0.4s;
}
