.quill {
  .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  .ql-editor {
    max-height: 400px;
    min-height: 230px;
  }
  .ql-container {
    overflow-y: auto;
    height: auto;
    margin-bottom: 10px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &--error {
    position: absolute;
    bottom: -10px;
    left: 13px;
    background: #fff;
  }
}
